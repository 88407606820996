export const LINKS = [
    {
        to: '/home',
        name: 'Home',
    },
    {
        to: '/comercial',
        name: 'Comercial',
    },
    {
        to: '/sac',
        name: 'SAC',
    },
    {
        to: '/bots',
        name: 'Bots',
    },
]