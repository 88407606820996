import React from 'react'
import ComercialGraficos from '../components/ComercialGraficos/ComercialGraficos'

const Comercial = () => {
  return (
    <div>
      <ComercialGraficos />
    </div>
  )
}

export default Comercial