import React from 'react'
import Pesquisa from '../components/SACPesquisa/Pesquisa'

const SAC = () => {
  return (
    <div>
      <Pesquisa />
    </div>
  )
}

export default SAC